@font-face {
  font-family: 'Bw Gradual';
  src: url('../fonts/Bw-Gradual/BwGradual-Regular.eot');
  src:
    local('Bw Gradual Regular'),
    local('BwGradual-Regular'),
    url('../fonts/Bw-Gradual/BwGradual-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Bw-Gradual/BwGradual-Regular.woff2') format('woff2'),
    url('../fonts/Bw-Gradual/BwGradual-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Bw Gradual';
  src: url('../fonts/Bw-Gradual/BwGradual-Bold.eot');
  src:
    local('Bw Gradual Bold'),
    local('BwGradual-Bold'),
    url('../fonts/Bw-Gradual/BwGradual-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Bw-Gradual/BwGradual-Bold.woff2') format('woff2'),
    url('../fonts/Bw-Gradual/BwGradual-Bold.woff') format('woff');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'Bw Gradual';
  src: url('../fonts/Bw-Gradual/BwGradual-ExtraBoldItalic.eot');
  src:
    local('Bw Gradual Extra Bold Italic'),
    local('BwGradual-Extra-Bold-Italic'),
    url('../fonts/Bw-Gradual/BwGradual-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Bw-Gradual/BwGradual-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/Bw-Gradual/BwGradual-ExtraBoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
