.running-line {
  overflow: hidden;
  height: 110px;

  &_container {
    height: 59px;
    overflow: hidden;
    margin-top: 28px;
    display: block;
    color: $color-text;
  }

  .running-line-slider {
    .line-item {
      display: flex;
      align-items: center;
      min-width: 280px;
      height: 60px;
      img {
        width: 40px;
        margin-right: 20px;
      }

      p {
        font-family: 'Bw Gradual';
        font-weight: 700;
        font-size: 22px;
        text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: 886px) {
  .running-line {
    height: 90px;
    &_container {
      height: 60px;
      overflow: hidden;
      margin-top: 17px;
    }

    .running-line-slider {
      .line-item {
        width: 230px;
        min-width: 230px;
        img {
          width: 30px;
          margin-right: 14px;
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
}
