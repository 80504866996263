.smart-wrapper-page {
  padding-top: 80px;
  padding-bottom: 80px;

  .smart-container {
    justify-content: space-between;
    display: flex;

    .image {
      img {
        max-width: 550px;
      }
    }

    &.right {
      flex-direction: row-reverse;
    }

    &.center {
      flex-direction: column;

      align-items: center;
      text-align: center;

      .images {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 40px;

        img {
          padding: 16px;
        }
      }

      .text-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .h3 {
          max-width: 100%;
          margin-bottom: 24px;
        }

        p {
          max-width: 830px;
          margin-bottom: 24px;
        }
      }
    }

    .h3 {
      &:not(.italic) {
        padding-bottom: 0;
      }

      &.italic {
        font-style: italic;
      }
    }

    .description {
      p {
        font-size: 28px;
        color: $white;
        line-height: 34px;
        max-width: 548px;
      }

      .btn {
        margin-top: 32px;
      }
    }
  }

  .h1 {
    margin-bottom: 16px;
  }

  .h3 {
    max-width: 753px;
    padding-bottom: 24px;
  }
}

// Container width
@media screen and (max-width: 1280px) {
  .smart-wrapper-page {
    padding: 60px 16px;
  }
}

// Tablet
@media screen and (max-width: 1200px) {
  .smart-wrapper-page {
    .smart-container {
      &.right {
        .image {
          img {
            width: 340px;
          }
          padding-right: 20px;
        }
      }
    }

    .image {
      img {
        width: 400px;
      }
    }
  }
}

// Mobile
@media screen and (max-width: 1006px) {
  .smart-wrapper-page {
    .smart-container {
      flex-direction: column !important;

      &.center .text-content {
        text-align: left;
      }

      .images {
        flex-direction: column;
        padding-top: 10px !important;
      }
      .image {
        width: 100%;
        margin-top: 40px;
        img {
          width: 100% !important;
          max-width: fit-content;
        }
      }
    }
  }
}
