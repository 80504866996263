.hero-wrapper-page {
  overflow: visible;

  .content {
    display: flex;
    padding: 20px 0;

    .description {
      min-width: 451px;
      margin-right: 35px;

      .title {
        white-space: nowrap;
      }

      .back-link {
        background-image: url($chevron-down);
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(90deg);
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;

        &.bg-black {
          filter: invert(1);
        }
      }

      .h1 {
        margin-bottom: 16px;
      }

      .title {
        font-size: 55px;
        font-family: 'Bw Gradual';
        font-weight: 500;
        line-height: 61px;
      }

      .text-content {
        margin-top: 40px;
        margin-bottom: 18px;
        max-width: 640px;

        p {
          margin-bottom: 24px;
          font-size: 24px;
        }
      }
    }
  }

  .image {
    width: 100%;
    position: relative;
    max-width: 540px;
    &.mobile {
      display: none;
    }
    img {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

// Tablet
// @media screen and (max-width: 1200px) {
//   .hero-wrapper-page {
//     h2.title {
//       width: auto !important;
//       font-size: 67px !important;
//       line-height: 72px !important;
//       span {
//         line-height: 60px !important;
//         padding: 0 15px !important;
//       }
//     }

//     .text-content {
//       max-width: fit-content !important;
//     }
//   }
// }

// Container width
@media screen and (max-width: 1280px) {
  .hero-wrapper-page {
    padding: 0 16px;

    .content .description .title.long-text {
      font-size: 48px;
    }
  }
}

// Mobile
@media screen and (max-width: 860px) {
  .hero-wrapper-page {
    .content {
      .image {
        &:not(.mobile) {
          display: none;
        }
        &.mobile {
          display: flex;
          min-height: auto;
          height: auto;
          width: 100%;
          max-width: fit-content;
          img {
            transform: none;
            height: auto;
            width: 100%;
            position: inherit;
            transform: none;
          }
        }
      }
      .description {
        min-width: auto;
        margin-right: 0;
        .text-content {
          max-width: fit-content;
        }
        h2.title {
          font-size: 36px !important;
          line-height: 44.5px !important;
          &.long-text {
            font-size: 30px !important;
            line-height: 34.5px !important;
          }
          span {
            line-height: 40px !important;
            padding: 0 11px;
          }
        }
      }
    }
  }
}
