.reach-out-wrapper {
  border-radius: 60px;
  padding: 24px 26px;
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 80px);
  width: 100%;
  position: relative;
  overflow: auto;
  max-height: 900px;
  overflow: hidden;

  .message-container {
    display: none;
  }

  .images {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: calc(100% - 80px);
    top: 40px;
    width: calc(100% - 580px);

    .logo {
      width: 155px;
      height: 155px;
    }

    .cover {
      width: 100%;
      height: calc(100% - 160px);
    }
  }

  .reach-out-form {
    border-radius: 40px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 400px;
    box-sizing: content-box;
    height: calc(100% - 160px);
    overflow: auto;
    position: absolute;
    right: 40px;
    top: 40px;

    .title {
      font-size: 32px;
      line-height: 52.8px;
      margin-bottom: 16px;
    }

    p {
      font-size: 16px;
      margin-bottom: 32px;
    }

    .field {
      &.max-space {
        height: 100%;

        .textarea {
          height: calc(100% - 24px);
        }
      }

      .input,
      .textarea {
        width: 100%;
        border-radius: 16px;
        border: none;
        padding: 19px 24px;
        margin-bottom: 24px;
        font-size: 16px;
        resize: none;
        font-family: 'TT Interfaces', sans-serif;
        font-size: 16px;

        &::placeholder {
          color: $purple;
        }
      }
    }
  }
}

[data-modal='reach-out'] {
  &.error {
    .reach-out-wrapper {
      background-color: $red !important;
    }
  }

  &.success,
  &.error {
    .reach-out-form {
      display: none;
    }

    .message-container {
      display: flex;
    }

    .reach-out-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .message-container {
        padding-top: 80px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .message-title {
          font-weight: 500;
          font-size: 45px;
          line-height: 48px;
          font-family: 'Bw Gradual';
          max-width: 600px;
          text-align: center;
        }

        p {
          font-size: 18px;
          max-width: 600px;
          text-align: center;
          padding-top: 24px;
        }

        .btn {
          margin-top: 32px;
        }
      }

      .images {
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        position: inherit;
        width: 100%;
        top: unset;

        .logo {
          position: absolute;
          top: 20px;
          left: 20px;
        }

        .cover {
          margin-top: 150px;
          width: fit-content;
          width: 340px;
          height: 340px;
        }
      }
    }
  }
}

@media screen and (max-width: 886px) {
  [data-modal='reach-out'] {
    &.success {
      .reach-out-wrapper {
        background-color: $yellow !important;

        .message-container {
          padding-top: 80px;

          .message-title {
            font-size: 30px;
            line-height: normal;
          }
        }

        .images {
          height: 100px;

          .logo {
            height: 120px;
            width: 120px;
          }

          .cover {
            display: none;
          }
        }
      }
    }
  }

  #modal {
    .container {
      padding: 16px;
    }
  }

  .reach-out-wrapper {
    .reach-out-form {
      position: static;
    }

    .reach-out-form .title {
      font-size: 32px;
      margin-bottom: 16px;
      margin-top: 6px;
    }

    padding: 0;
    overflow: auto;
    height: calc(100vh - 40px);
    padding: 20px;
    background-color: $purple !important;

    .reach-out-form {
      height: calc(100% - 20px);

      .field {
        .textarea,
        .input {
          font-size: 16px;
        }
      }

      .btn {
        margin-bottom: 20px;
      }
    }

    .reach-out-form {
      position: sticky;
      max-width: 100%;
      width: 100%;
      padding: 0;
      padding-top: 10px;
      border-radius: 0;
      overflow-y: auto;
    }

    .images {
      display: none;
    }
  }
}

.checkbox {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  margin-bottom: 32px;
  user-select: none;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox input:checked ~ .checkbox__checkmark {
  background-color: $pink;
}

.checkbox input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}

.checkbox:hover input ~ .checkbox__checkmark {
  background-color: #eee;
}

.checkbox:hover input:checked ~ .checkbox__checkmark {
  background-color: $pink;
}

.checkbox .checkbox__checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: #eee;
  transition: background-color 0.25s ease;
  border-radius: 4px;
}

.checkbox .checkbox__checkmark:after {
  content: '';
  position: absolute;
  left: 8px;
  top: 4px;
  width: 8px;
  height: 12px;
  border: solid $white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}

.checkbox .checkbox__body {
  line-height: 1.4;
  font-size: 16px;
  margin-top: 6px;
  margin-left: 10px;

  p {
    margin: 0;
  }
}
