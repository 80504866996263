/**
 * Colors
 * Palettes: https://codepen.io/janeRivas/pen/RwbBbMj
 */
$black-full: #000000;
$black: #121212;
$blue: #82aaff;
$green: #c3e88d;

$red: #ff6f6f;
$white: #ffffff;
$creme-white: #fffff4;
$yellow: #fbf81e;
$orange: #ffd342;
$purple: #5201be;
$pink: #ff58fc;
$pink-100: #febaff;
$green-100: #a6fe5a;
$color-text: #1e0b37;
$color-bg-dark: #1e0b37;

.bg-black {
  background-color: $black-full !important;
}

.bg-white {
  background-color: $white !important;
}

.bg-orange {
  background-color: $orange !important;
}

.bg-pink {
  background-color: $pink !important;
}

.bg-pink-100 {
  background-color: $pink-100 !important;
}

.bg-green-100 {
  background-color: $green-100 !important;
}

.bg-yellow {
  background-color: $yellow !important;
}

.bg-green {
  background-color: $green-100 !important;
}

.bg-dark {
  background-color: $color-bg-dark !important;
}

.bg-purple {
  background-color: $purple !important;
}

.text-white {
  color: $white !important;
}

.text-black {
  color: $black !important;
}
