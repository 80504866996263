.cookies-banner {
  background-color: $purple;
  position: fixed;
  bottom: 40px;
  right: calc((100% - 1145px) / 2);
  @media screen and (max-width: 1200px) {
    right: 16px;
  }
  @media screen and (max-width: 440px) {
    left: 16px;
  }
  color: $white;
  max-width: 390px;
  padding: 24px;
  border-radius: 28px;
  display: none;
  &.show {
    display: block;
  }
  .close {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 18px;
    top: 18px;
    background-image: url($cross);
    background-repeat: no-repeat;
    background-size: contain;
  }
  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 25.4px;
    margin-bottom: 16px;
    font-family: 'Bw Gradual';
  }
  p {
    font-size: 16px;
    line-height: 18.4px;
    font-weight: 300;
    margin-bottom: 16px;
  }
  .allow-link {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    color: $white;
    font-family: 'TT Interfaces';
  }
}
