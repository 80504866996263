@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-SemiBoldItalic.eot');
  src:
    local('TT Interfaces SemiBold Italic'),
    local('TTInterfaces-SemiBoldItalic'),
    url('../fonts/TT-Interfaces/TTInterfaces-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-SemiBoldItalic.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-MediumItalic.eot');
  src:
    local('TT Interfaces Medium Italic'),
    local('TTInterfaces-MediumItalic'),
    url('../fonts/TT-Interfaces/TTInterfaces-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-MediumItalic.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-MediumItalic.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-BoldItalic.eot');
  src:
    local('TT Interfaces Bold Italic'),
    local('TTInterfaces-BoldItalic'),
    url('../fonts/TT-Interfaces/TTInterfaces-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-BoldItalic.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-BoldItalic.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-Italic.eot');
  src:
    local('TT Interfaces Italic'),
    local('TTInterfaces-Italic'),
    url('../fonts/TT-Interfaces/TTInterfaces-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-Italic.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-Italic.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-SemiBold.eot');
  src:
    local('TT Interfaces SemiBold'),
    local('TTInterfaces-SemiBold'),
    url('../fonts/TT-Interfaces/TTInterfaces-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-SemiBold.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-SemiBold.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-Medium.eot');
  src:
    local('TT Interfaces Medium'),
    local('TTInterfaces-Medium'),
    url('../fonts/TT-Interfaces/TTInterfaces-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-Medium.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-Medium.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-ExtraBold.eot');
  src:
    local('TT Interfaces ExtraBold'),
    local('TTInterfaces-ExtraBold'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraBold.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraBold.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-BlackItalic.eot');
  src:
    local('TT Interfaces Black Italic'),
    local('TTInterfaces-BlackItalic'),
    url('../fonts/TT-Interfaces/TTInterfaces-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-BlackItalic.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-BlackItalic.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-Bold.eot');
  src:
    local('TT Interfaces Bold'),
    local('TTInterfaces-Bold'),
    url('../fonts/TT-Interfaces/TTInterfaces-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-Bold.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-Bold.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-Light.eot');
  src:
    local('TT Interfaces Light'),
    local('TTInterfaces-Light'),
    url('../fonts/TT-Interfaces/TTInterfaces-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-Light.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-Light.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-ExtraBoldItalic.eot');
  src:
    local('TT Interfaces ExtraBold Italic'),
    local('TTInterfaces-ExtraBoldItalic'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraBoldItalic.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-Regular.eot');
  src:
    local('TT Interfaces Regular'),
    local('TTInterfaces-Regular'),
    url('../fonts/TT-Interfaces/TTInterfaces-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-Regular.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-Regular.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-Black.eot');
  src:
    local('TT Interfaces Black'),
    local('TTInterfaces-Black'),
    url('../fonts/TT-Interfaces/TTInterfaces-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-Black.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-Black.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-ThinItalic.eot');
  src:
    local('TT Interfaces Thin Italic'),
    local('TTInterfaces-ThinItalic'),
    url('../fonts/TT-Interfaces/TTInterfaces-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-ThinItalic.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-ThinItalic.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-LightItalic.eot');
  src:
    local('TT Interfaces Light Italic'),
    local('TTInterfaces-LightItalic'),
    url('../fonts/TT-Interfaces/TTInterfaces-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-LightItalic.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-LightItalic.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-ExtraLightItalic.eot');
  src:
    local('TT Interfaces ExtLt Ita'),
    local('TTInterfaces-ExtraLightItalic'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraLightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraLightItalic.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraLightItalic.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-Thin.eot');
  src:
    local('TT Interfaces Thin'),
    local('TTInterfaces-Thin'),
    url('../fonts/TT-Interfaces/TTInterfaces-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-Thin.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-Thin.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TT-Interfaces/TTInterfaces-ExtraLight.eot');
  src:
    local('TT Interfaces Extra Light'),
    local('TTInterfaces-ExtraLight'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraLight.woff2') format('woff2'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraLight.woff') format('woff'),
    url('../fonts/TT-Interfaces/TTInterfaces-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
