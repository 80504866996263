.breadcrumbs {
  font-size: 16px;
  padding-bottom: 16px;
  a,
  span {
    color: $black;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    &::after {
      content: '';
      background-image: url($chevron-down);
      transform: rotate(-90deg) translateX(-4px);
      width: 20px;
      height: 20px;
      display: inline-block;
      filter: invert(1);
      background-repeat: no-repeat;
      margin-left: 8px;
      background-size: contain;
    }
  }

  &.bg-pink {
    a,
    span {
      color: $black;
      &::after {
        filter: invert(1);
      }
    }
  }

  &.bg-white {
    a,
    span {
      opacity: 0.5;
      text-transform: none;
      &::after {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }

  span {
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1190px) {
  .breadcrumbs .container {
    padding: 0 16px;
  }
}
