.page-privacy {
  color: $color-text;

  .page-content {
    padding-left: 100px;
  }

  .space-bottom {
    margin-bottom: 80px;
  }

  .space-bottom-small {
    margin-bottom: 40px;
  }

  .space-bottom-xsmall {
    margin-bottom: 16px !important;
  }

  &.text-content {
    p {
      margin-bottom: 32px;
    }
  }

  p {
    max-width: 600px;
  }

  .h2 {
    margin-top: 32px;
    margin-bottom: 16px;
    font-family: 'Bw Gradual';
    font-weight: 500;
    font-size: 28px;
  }

  ul,
  .num-list-small {
    padding-left: 100px;
    max-width: 600px;

    li {
      font-family: 'TT Interfaces';
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }

  .page-title {
    font-family: 'Bw Gradual';
    font-weight: 500;
    font-size: 54px;
    line-height: 64px;
    margin-top: 20px;
    margin-bottom: 32px;
  }

  .num-list-small {
    font-weight: 300;
    line-height: 24px;
    font-family: 'TT Interfaces';
  }

  .num-list {
    font-family: 'Bw Gradual';
    font-weight: 500;
    font-size: 28px;
    padding-left: 100px;
    margin-top: 80px;
    max-width: 694px;
    counter-reset: items;
    list-style: none;

    &.head-smaller {
      font-size: 18px;
      font-family: 'TT Interfaces';
    }

    &.no-margin {
      margin-top: 16px;
    }

    .num-item {
      margin-bottom: 40px;
      counter-increment: items;
      list-style: none;
      p {
        margin: 16px 0;
      }

      ul {
        list-style-type: disc;
        padding-left: 100px;

        li {
          font-family: 'TT Interfaces';
          font-size: 18px;
          font-weight: 300;
          line-height: 24px;
        }
      }

      &::before {
        content: counters(items, '.') '. ';
      }

      p {
        // margin: 16px 0;
        // padding-left: 80px;
        font-family: 'TT Interfaces';
        // margin-bottom: 0;
        padding-left: 0;
      }

      .num-list {
        padding: 0;
        font-size: 24px;
        margin: 0;
        margin-top: 16px;
      }
    }
  }
}

@media screen and (max-width: 1190px) {
  .page-privacy > .container {
    padding: 0 16px;
  }
}

@media screen and (max-width: 686px) {
  .page-privacy {
    .page-content {
      padding-left: 0;
    }
    .page-title {
      font-size: 32px;
      line-height: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .h2 {
      font-size: 20px !important;
    }

    ul,
    .num-list-small {
      padding-left: 20px;

      li {
        font-size: 16px;
      }
    }

    p {
      font-size: 16px;
      margin-bottom: 20px !important;
    }

    .num-list {
      padding-left: 0;
      margin-top: 20px;
      font-size: 20px;

      .num-item {
        padding-left: 0;
        font-size: 16px;

        p {
          padding-left: 0;
          font-size: 16px;
        }

        ul {
          padding-left: 20px;
        }

        li {
          font-size: 16px !important;
        }

        .num-list {
          padding-left: 0;
          margin-top: 10px;
          font-size: 20px;

          .num-item {
            padding: 0;
          }

          ul {
            padding-left: 20px;
          }
        }
      }
    }
  }
}
