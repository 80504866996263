.image-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 16px;
  border-radius: 60px;
  padding: 72px;
  overflow: hidden;
  max-height: 434px;

  &.text-white {
    .description {
      .h1 {
        color: $white;
        &::before {
          filter: invert(1);
        }
      }
    }
  }

  &.big-description {
    .description {
      p {
        font-weight: 500;
        font-family: 'Bw Gradual';
        font-size: 25px;
        line-height: 35px;
      }
    }
  }

  .description {
    width: 100%;
    .btn {
      margin-top: 32px;
    }

    .h1 {
      margin-bottom: 8px;
    }

    .h3 {
      margin-bottom: 10px;
    }

    p {
      font-size: 28px;
      line-height: 34px;
    }

    .icon {
      width: 60px;
      height: 30px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: -7px;
      background-size: contain;

      &.visa {
        background-image: url($visa);
      }

      &.mastercard {
        background-image: url($mastercard);
      }
    }
  }

  &.left {
    padding-left: 0;

    .image {
      margin-right: 32px;
      img {
        width: 433px;
        margin-left: -9px;
        margin-bottom: 11px;
      }
    }
  }

  &.right {
    flex-direction: row-reverse;
    padding-right: 0;

    .image {
      margin-left: 80px;
      img {
        width: 485px;
        margin-right: -80px;
        margin-bottom: -80px;
      }
    }
  }

  &.special {
    .image {
      margin-left: 80px;
      img {
        width: 419px;
        padding-left: 40px;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}

// Tablet
@media screen and (max-width: 1200px) {
  .boxes-wrapper {
    .description {
      .h3 {
        font-size: 35px;
        margin-top: 20px;
        padding-bottom: 0;
        margin-bottom: 16px;
      }
      p {
        font-size: 20px;
        .icon {
          width: 40px;
          background-size: contain;
          height: 20px;
          margin-bottom: -5px;
        }
      }
    }

    .image-box {
      &.left {
        padding-left: 0;

        .image {
          margin-right: 80px;
          img {
            width: 300px;
            margin-left: 0;
            margin-bottom: 0;
          }
        }
      }

      &.right {
        flex-direction: row-reverse;
        padding-right: 0;

        .image {
          margin-left: 80px;
          img {
            width: 300px;
            margin-right: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    // .description {
    //   .h3 {
    //     font-size: 47px;
    //     padding-bottom: 0;
    //   }
    // }
  }
}

// Mobile
@media screen and (max-width: 760px) {
  .boxes-wrapper {
    .description {
      .h3 {
        font-size: 35px;
        margin-top: 20px;
        padding-bottom: 0;
        margin-bottom: 16px;
      }
      p {
        font-size: 20px;
        .icon {
          width: 40px;
          background-size: contain;
          height: 20px;
          margin-bottom: -5px;
        }
      }
    }
    .image-box {
      max-height: fit-content;
      flex-direction: column-reverse !important;
      padding: 40px 24px !important;
      padding-bottom: 0 !important;
      &.special {
        background-color: transparent !important;
        padding-top: 0 !important;
        overflow: visible;
        padding: 0 !important;
        margin-top: -40px;
        .image {
          margin-top: 40px !important;
          background-color: $white;
          border-radius: 30px;
          padding: 40px;
          img {
            padding: 0;
          }
        }
      }
      .image {
        width: 100%;
        margin: 0 !important;
        img {
          width: 100% !important;
        }
      }
    }
  }
}
