.header {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  align-items: center;

  .logo {
    max-width: 175px;
    width: 175px;
    display: flex;
    height: 175px;
    margin-right: 30px;

    img {
      width: inherit;
    }
  }

  .nav-menu-wrapper {
    max-width: 812px;
    position: relative;
    width: 100%;
    height: 75px;
  }

  .nav-menu {
    display: flex;
    padding: 13px;
    border-radius: 80px;
    height: fit-content;
    align-items: center;
    max-width: 914px;
    width: 100%;
    justify-content: space-between;
    max-height: 75px;

    &.bg-yellow {
      .nav-contacts .link {
        color: $black;
        &::after {
          background-color: $black;
        }
      }
      .nav-list .nav-item .nav-link {
        color: $black;
        position: relative;

        &::after {
          filter: invert(1);
        }
      }
    }

    .nav-list {
      display: flex;
      list-style: none;

      .nav-item {
        display: flex;
        align-items: center;
        padding-bottom: 30px;
        margin-bottom: -30px;
        position: relative;

        &.expanded:hover {
          // &::after {
          //   content: "";
          //   position: absolute;
          //   top: 6px;
          //   right: -38px;
          //   width: 17px;
          //   height: 17px;
          //   background-color: #5201be;
          //   z-index: 2;
          //   border-radius: 0% 100% 25% 0% / 25% 100% 0% 25%;
          //   transform: rotate(180deg);
          // }

          // &::before {
          //   content: "";
          //   position: absolute;
          //   top: 9px;
          //   right: -37px;
          //   width: 24px;
          //   height: 24px;
          //   background-color: #ff58fc;
          //   z-index: 2;
          //   transform: rotate(180deg);
          // }

          .nav-link {
            background-color: $pink;
            margin-left: -20px;
            margin-right: -20px;
            padding: 14px 20px;
            // margin-top: -14px;
            margin-top: 0;
            padding-bottom: 15px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            transition: none;
            font-size: 14px;
            position: relative;
            &::before {
              content: '';
              background-color: $pink;
              width: 100%;
              height: 40px;
              position: absolute;
              bottom: -40px;
              left: 0;
            }
            &::after {
              transform: rotate(180deg);
            }
          }

          .sub-menu {
            max-height: max-content;
            padding-top: 10px;
            padding-bottom: 20px;
            transition: all 0.3s ease;
            min-width: calc(100% + 40px);
          }
        }

        &.submenu-pink {
          &.expanded:hover .nav-link {
            background-color: $pink;
            color: $white;
            text-decoration: none;

            &::after {
              filter: invert(0);
            }
          }
          &.active {
            .nav-link {
              color: $pink;
              text-decoration: underline;
            }
          }
        }
        // &.expanded.submenu-pink:hover .nav-link {
        //   background-color: $pink;
        // }

        &.submenu-purple {
          &.active {
            .nav-link {
              color: $purple;
              text-decoration: underline;
            }
          }

          &.expanded:hover .nav-link {
            background-color: $purple;
            color: $white;
            text-decoration: none;
            &::before {
              background-color: $purple;
            }
          }
        }

        &.submenu-green {
          &.active {
            .nav-link {
              color: $pink;
              text-decoration: underline;
            }
          }
          &.expanded:hover .nav-link {
            background-color: $green-100;
            color: $black;
            text-decoration: none;
            &::before {
              background-color: $green-100;
            }
            &::after {
              filter: invert(1);
            }
          }
        }

        .sub-menu {
          padding-top: 0;
          padding-bottom: 0;
          max-height: 0;
          transition: none;
          overflow: hidden;
          padding-bottom: 20px;
          z-index: 1;
          position: absolute;
          padding: 0 20px;
          top: 53px;
          list-style: none;

          white-space: nowrap;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          border-top-right-radius: 20px;
          left: -20px;
          min-width: calc(100% + 40px);

          &.bg-green {
            .link {
              color: $black;
            }
          }

          .sub-item {
            &:not(:last-child) {
              margin-bottom: 15px;
            }

            .link {
              font-weight: 400;
            }
          }
        }

        &:not(:last-child) {
          margin-right: 32px;
        }

        &:first-child {
          margin-left: 28px;
        }

        .nav-link {
          text-transform: uppercase;
        }

        &.expanded {
          .nav-link {
            display: flex;
            align-items: center;

            &::after {
              content: '';
              background-image: url($chevron-down);
              width: 24px;
              height: 24px;
              display: inline-block;
              background-repeat: no-repeat;
              margin-left: 8px;
              transition: transform 0.3s ease;
            }
          }
        }
      }
    }

    .nav-contacts {
      display: flex;
      align-items: center;
      margin-left: 32px;

      .btn.bg-green {
        color: $black;
      }

      .burger-mobile-menu {
        display: none;
      }

      .link {
        color: $white;
        text-decoration: none;
        display: flex;
        cursor: pointer;

        &::after {
          content: '';
          display: inline-block;
          background-color: $white;
          height: 20px;
          width: 1px;
          margin: 0 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .header .nav-menu .nav-list .nav-item:not(:last-child) {
    margin-right: 20px;
  }
}

@media screen and (max-width: 1080px) {
  .header .logo {
    width: 130px;
    max-width: 130px;
    height: auto;
  }
}

// Mobile
@media screen and (max-width: $breakpoint-sm) {
  .header .logo {
    width: 92px;
    max-width: 90px;
    height: auto;
  }

  .header .nav-menu .nav-contacts {
    margin-left: -30px;
    .link::after {
      margin: 0 20px;
    }
  }
}

// Mobile
@media screen and (max-width: 1006px) {
  body:not(.mobile-menu-open) {
    header {
      &.bg-yellow,
      &.bg-green,
      &.bg-orange {
        .header .nav-menu .nav-contacts {
          .link {
            color: $black !important;
            &::after {
              background-color: $black !important;
            }
          }
          .burger-mobile-menu {
            filter: invert(0);
          }
        }
      }
    }
  }

  .mobile-menu-open {
    header {
      &.bg-pink {
        .header .nav-menu .nav-contacts {
          .link {
            color: $white !important;
            &::after {
              background-color: $white !important;
            }
          }
          .burger-mobile-menu {
            filter: invert(1);
          }
        }
      }
    }
  }

  .mobile-menu-open {
    .header-top {
      position: fixed;
      background-color: $color-bg-dark;
      z-index: 40;
      .nav-contacts {
        .link {
          color: $white !important;
          &::after {
            background-color: $white !important;
          }
        }
        .burger-mobile-menu {
          background-image: url($cross);
          filter: invert(0) !important;
        }
      }
    }
  }

  .nav-menu-wrapper {
    max-width: fit-content !important;
    height: auto !important;
    .nav-menu {
      background-color: transparent !important;
      padding: 0;
    }
    nav {
      display: none;
    }
    .nav-contacts {
      .link {
        color: $black !important;
        &::after {
          background-color: $black !important;
        }
      }
      .btn {
        display: none;
      }
      .burger-mobile-menu {
        display: block !important;
        width: 24px;
        height: 24px;
        background-image: url($burger);
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }
  }
}

// Container width
@media screen and (max-width: 1280px) {
  .header {
    padding: 10px 16px;
  }
}
