.hero-wrapper {
  background-color: $yellow;
  overflow: hidden;

  .content {
    display: flex;
    padding: 40px 0;

    .description {
      min-width: 651px;

      .h1 {
        margin-bottom: 16px;
      }

      .title {
        font-size: 75px;
        font-family: 'Bw Gradual';
        font-weight: 700;
        line-height: 79px;

        span {
          color: $yellow;
          background-color: $pink;
          border-radius: 60px;
          padding: 0 36px;
          display: inline-block;
          line-height: 63px;
        }
      }

      .text-content {
        margin-top: 40px;
        margin-bottom: 18px;
        max-width: 445px;

        p {
          margin-bottom: 24px;
          font-size: 18px;
        }
      }
    }
  }

  .image {
    width: 100%;
    width: 100%;
    position: relative;
    &.mobile {
      display: none;
    }
    img {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media screen and (max-width: 1200px) {
  .hero-wrapper {
    h2.title {
      font-size: 67px !important;
      line-height: 72px !important;
      span {
        line-height: 60px !important;
        padding: 0 15px !important;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .hero-wrapper {
    padding: 0 16px;
  }
}

// Mobile
@media screen and (max-width: 1006px) {
  .hero-wrapper {
    .content {
      .text-content {
        max-width: fit-content !important;
      }
      .image {
        &:not(.mobile) {
          display: none;
        }
        &.mobile {
          display: flex;
          min-height: auto;
          height: auto;
          width: 100%;
          img {
            transform: none;
            height: auto;
            width: 100%;
            position: inherit;
            transform: none;
          }
        }
      }
      .description {
        min-width: auto;
        h2.title {
          font-size: 40px !important;
          line-height: 44.5px !important;
          span {
            line-height: 40px !important;
            padding: 0 11px;
          }
        }
      }
    }
  }
}
