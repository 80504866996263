.mobile-menu-open {
  overflow: hidden;
}

.mobile-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $color-bg-dark;
  transform: translateY(-150%);
  top: 145px;
  overflow: auto;
  padding-top: 32px;
  // transition: transform 0.3s ease;

  &.show {
    transform: translateY(0);
    transition: transform 0.3s ease;
  }

  .address-mobile,
  .footer-copyright-mobile {
    font-weight: 500;
    font-size: 16px;
    padding: 16px;
    color: $white;
    .description {
      margin-top: 32px;
      margin-bottom: 24px;
    }
    .copyright {
      margin-bottom: 124px;
    }
  }

  .footer-links-mobile {
    padding: 16px;
    list-style: none;
    margin-top: 32px;
    .nav-item {
      margin-bottom: 24px;
      .link {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }

  .footer-social-mobile {
    padding: 16px;
    .link {
      margin-right: 16px;
    }
  }

  .nav-list {
    padding: 16px;
    list-style: none;
    border-bottom: 1px solid $purple;

    .nav-item {
      position: relative;

      &:last-child {
        margin-bottom: 24px;
      }

      &:not(:last-child) {
        margin-bottom: 40px;
      }
      &:not(:has(.sub-menu)) .nav-link::after {
        content: '';
        background-image: url($chevron-down);
        transform: rotate(-90deg);
        width: 24px;
        height: 24px;
        display: inline-block;
        background-repeat: no-repeat;
        margin-left: 8px;
      }
      .nav-link {
        color: $white;
        font-size: 20px;
        font-weight: 600;
        text-decoration: none;
        display: block;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .sub-menu {
        list-style: none;
        margin-top: 16px;
        .sub-item {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
          .link {
            font-size: 16px;
            font-weight: 400;
            text-decoration: none;
            display: block;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &::after {
              content: '';
              background-image: url($chevron-down);
              transform: rotate(-90deg);
              width: 24px;
              height: 24px;
              display: inline-block;
              background-repeat: no-repeat;
              margin-left: 8px;
            }
          }
        }
      }
    }

    &.footer-links {
      .nav-item {
        background-color: red;
      }
    }
  }
}

// Desktop
@media screen and (min-width: 1007px) {
  .mobile-menu {
    display: none;
  }
}

// Mobile big
@media screen and (max-width: 1006px) {
  .mobile-menu {
    top: 145px;
    height: calc(100vh - 145px);
  }
}

// Mobile small
@media screen and (max-width: $breakpoint-sm) {
  .mobile-menu {
    top: 108px;
    height: calc(100vh - 108px);
  }
}
