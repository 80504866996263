.footer {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  align-items: center;
  flex-direction: column;

  .separator {
    border-top: 1px solid $purple;
    width: 100%;
  }

  .footer-links-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1006px) {
      display: none;
    }
    .links-emplty {
      max-width: 175px;
      width: 175px;
      display: flex;
      margin-right: 30px;
    }
    .footer-links {
      width: 100%;
      max-width: 812px;
      padding-left: 40px;
      @media screen and (max-width: 1280px) {
        padding-left: 25px;
      }
    }
  }
}

// Mobile
@media screen and (max-width: 1006px) {
  footer {
    .header .nav-menu .nav-contacts {
      .link {
        color: $white !important;
        &::after {
          background-color: $white !important;
        }
      }
      .burger-mobile-menu {
        filter: invert(1);
      }
    }
  }
  .footer-links {
    display: none;
  }

  .separator {
    display: none;
  }
}

.footer-links {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;

  @media screen and (max-width: 1006px) {
    display: none;
  }

  @media screen and (max-width: 1280px) {
    padding: 10px 16px;
  }

  .links-emplty {
    width: 220px;
  }

  .links {
    display: flex;
    flex-direction: column;
    &:nth-child(2) {
      display: flex;
      // justify-content: center;
      margin-left: -40px;
    }

    &:nth-child(3) {
      display: flex;
      // justify-content: center;
    }

    &:last-child {
      text-align: right;
      padding: 0;
    }

    .link {
      display: block;
      margin-bottom: 16px;
      width: 100%;
    }
  }
}

.footer-social {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 32px;

  @media screen and (max-width: 1280px) {
    padding: 10px 16px;
  }

  @media screen and (max-width: 1006px) {
    display: none;
  }

  .social-links {
    .link {
      margin-right: 16px;
    }
  }

  .address {
    text-align: right;
    color: $white;
    font-weight: 500;
  }
}

.footer-copyright {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
  color: $white;

  @media screen and (max-width: 1280px) {
    padding: 10px 16px;
  }

  @media screen and (max-width: 1006px) {
    display: none;
  }

  .description {
    max-width: 559px;
  }

  padding-bottom: 60px;
}
