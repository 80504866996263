.boxes-wrapper,
.hits-wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
  .h1 {
    margin-bottom: 16px;
  }
  .h3 {
    max-width: 753px;
    padding-bottom: 24px;
    font-size: 40px;
  }
}

@media screen and (max-width: 1280px) {
  .boxes-wrapper,
  .hits-wrapper {
    padding: 60px 16px;
  }
}

.hits-wrapper {
  padding-top: 0;

  .hits-container {
    .hits-slider {
      .hits-item {
        &__inner {
          display: flex;
          align-items: center;
          .image {
            padding-right: 100px;
          }
          .description {
            p {
              font-size: 33px;
              color: $white;
              line-height: 46px;
            }
            .btn {
              margin-top: 32px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1006px) {
  .hits-container {
    overflow: hidden;
    padding-bottom: 100px;
    .hits-slider {
      .hits-item {
        &__inner {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          .image {
            padding-right: 0 !important;
            img {
              height: 300px;
            }
          }
        }
        .description {
          p {
            font-size: 18px !important;
            line-height: 26px !important;
          }
        }
      }
    }

    .slick-dots {
      text-align: left;
      height: 100px;
      bottom: -140px;
    }
  }
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;

  &:not(.slick-active) {
    button {
      &::before {
        border-color: $white;
        background-color: $white;
      }
    }
  }
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  width: 8px;
  height: 8px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  background-color: $pink;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  text-align: center;
  color: $pink;
  border: 3px solid $pink;
  border-radius: 50%;
  -webkit-font-smoothing: antialiased;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: $pink;
  width: 8px;
  background-color: $pink;
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
}
.slick-dots li button::before {
  content: '';
}
