/* modal part */
body {
  &.scroll-block {
    overflow: hidden;
  }
}

#modal-background {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#modal {
  width: 100%;
  position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  padding: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  font-size: 1.5rem;
  animation-name: animatemodal;
  animation-duration: 0.4s;

  .close {
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    // right: 64px;
    // top: 80px;
    background-image: url($cross);
    background-repeat: no-repeat;
    background-size: contain;

    right: 45px;
    top: 60px;
  }
}

// Mobile
@media screen and (max-width: 886px) {
  #modal {
    padding: 0;
    height: 100vh;
    overflow: hidden;

    .close {
      width: 28px;
      height: 28px;
      right: 5px;
      top: 30px;
    }
  }
}

/* modal part end */

/* animation part */
@keyframes animatemodal {
  from {
    // top: -300px;
    opacity: 0;
  }
  to {
    // top: 50%;
    opacity: 1;
  }
}
/* animation part end */
