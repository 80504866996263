/**
 * Common styles
 */

//  body {
//   transform: scale(0.8);
//   transform-origin: 0 0;
// }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

@function rem($size) {
  @return calc(#{$size} / 16px * 1rem);
}

body {
  font:
    400 1.3rem/1.4 'TT Interfaces',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  max-width: 1145px;
  padding: 0;
  margin: 0 auto;
  // background-color: rgba(245, 245, 220, 0.455);
}

.page-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.link {
  text-decoration: none;
  color: $white;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;

  &.highlighted {
    text-decoration: underline;
    font-weight: 300;
  }
}

.btn {
  text-decoration: none;
  color: $white;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  background-color: $pink;
  padding: 15px 25px;
  height: fit-content;
  text-transform: uppercase;
  border-radius: 60px;
  display: inline-block;
  cursor: pointer;
  border: none;
  &:disabled {
    opacity: 0.6;
  }
}

h1 {
  font-weight: inherit;
}

p {
  font-weight: 300;
  font-size: 18px;
}

.h1 {
  font-size: 22px;
  font-weight: 500;
  color: $color-text;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  &::before {
    content: '';
    background-image: url($diamond);
    width: 18px;
    height: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 14px;
    transition: transform 0.3s ease;
    background-size: contain;
  }

  &.diamond-yellow::before {
    background-image: url($diamond-yellow);
  }

  @media screen and (max-width: 1006px) {
    font-size: 15px;
    font-weight: 700;
  }
}

.h3 {
  font-size: 50px;
  font-family: 'Bw Gradual';
  font-weight: 500;
  line-height: 52px;

  @media screen and (max-width: 1006px) {
    font-size: 32px;
    font-weight: 700;
    line-height: 37px;
  }
}

.h4 {
  font-size: 33px;
  font-family: 'Bw Gradual';
  font-weight: 500;
  line-height: 40.6px;

  @media screen and (max-width: 1006px) {
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
  }
}

.h5 {
  font-size: 26px;
  font-family: 'Bw Gradual';
  font-weight: 500;
  line-height: 34.4px;

  @media screen and (max-width: 1006px) {
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
  }
}

.mb-4 {
  margin-bottom: 16px; /* 16px */
}
