.guarantees-wrapper {
  @media screen and (max-width: 1280px) {
    padding: 10px 16px;
  }
  .h1 {
    margin-bottom: 16px;
  }

  .h4,
  .h5 {
    max-width: 218px;
  }

  .number {
    font-family: 'Bw Gradual';
    font-weight: 500;
    font-size: 128px;
    position: absolute;
    bottom: 0;
    right: 30px;
  }

  .guarantees-container {
    &.theme-purple {
      .switch-boxes {
        .switch-box-item {
          background-color: $purple;
          p,
          .number {
            color: $white !important;
          }
        }
      }
    }

    &.theme-white {
      .switch-boxes {
        .switch-box-item {
          background-color: $white;
        }
      }
    }

    &.active-green {
      .switch-boxes {
        .switch-box-item.active {
          background-color: $green-100;
          .h4,
          .h5,
          .number,
          p {
            color: $black !important;
          }
        }
      }
    }

    &.active-purple {
      .switch-boxes {
        .switch-box-item.active {
          background-color: $purple;
          .h4,
          .h5,
          .number,
          p {
            color: $white !important;
          }
        }
      }
    }
  }
  .switch-boxes {
    display: flex;
    gap: 16px;
    margin-top: 40px;
    margin-bottom: 40px;

    .switch-box-item {
      background-color: $pink-100;
      padding: 30px;
      width: 25%;
      border-radius: 32px;
      cursor: pointer;
      transition: width 0.3s ease;
      min-height: 430px;
      position: relative;
      overflow: hidden;
      p {
        max-width: 229px;
        min-width: 229px;
      }
      .h4,
      .h5 {
        margin-bottom: 24px;
      }

      .link {
        color: $black;
        font-weight: 600;
        font-size: 16px;
        text-decoration: underline;
        text-transform: uppercase;
        display: block;
        margin-top: 24px;
      }

      .image {
        display: none;
      }
      &.active {
        background-color: $green-100;
        width: 50%;

        .image {
          position: absolute;
          right: 20px;
          bottom: 20px;
          display: block;
          img {
            width: 250px;
            height: 250px;
          }
        }
        .h4,
        .h5 {
          margin-bottom: 24px;
          max-width: 218px;
        }
        p {
          max-width: 229px;
          min-width: 229px;
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .guarantees-wrapper {
    .switch-boxes {
      flex-direction: column;
      .switch-box-item {
        width: 100%;
        height: 43px;
        min-height: 270px;
        transition: height 0.3s ease;
        height: 270px;
        .h4,
        .h5 {
          max-width: 400px;
        }
        p {
          // max-width: 80%;
          max-width: 400px;
          width: 400px;
        }
        &.active {
          width: 100%;
          height: 345px;

          .image {
            // position: absolute;
            right: 20px;
            // display: block;
            bottom: 20px;
            height: 250px;
            img {
              width: 250px;
              height: 250px;
            }
          }
          .h4,
          .h5 {
            max-width: 400px;
          }
          p {
            // max-width: 80%;
            max-width: 400px;
            width: 400px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 722px) {
  .guarantees-wrapper {
    .switch-boxes {
      flex-direction: column;
      .switch-box-item {
        width: 100%;
        height: 343px;
        min-height: 343px;
        transition: height 0.3s ease;
        .h4 {
          max-width: 100%;
        }
        p {
          // max-width: 80%;
          max-width: 80%;
          width: 80%;
        }
        &.active {
          width: 100%;
          height: 490px;
          .image {
            right: 10px;

            bottom: 10px;
            width: 220px;
            height: 220px;
            img {
              width: 220px;
              height: 220px;
            }
          }
          .h4 {
            max-width: 100%;
          }
          p {
            max-width: 80%;
            width: 80%;
          }
        }
      }
    }
  }
}
